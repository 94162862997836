import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../theme';

export const Footer = styled.footer`
  background: #111111;
  padding: 146px 100px 96px 100px;
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 72px;
  }
`;

export const NavLink = styled(Link)`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  transition: all 0.2s ease-in-out;
  &:hover,
  &:active,
  &:focus {
    color: #2878f9;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  position: relative;

  @media screen and (max-width: 768px) {
    margin-top: 22px;
  }
`;

export const SocialContainer = styled.div`
  max-width: 510px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  > button {
    margin: 0;

    > span {
      margin-left: 8px;
    }
  }

  @media screen and (max-width: 414px) {
    padding: 4rem 0;
    padding-right: 50%;
  }

  div {
    cursor: pointer;
    margin-right: 15px;
    width: 25px;
    height: 25px;

    &:hover {
      fill: rgb(80, 132, 193);
    }
  }
`;

export const MinPara = styled.p`
  font-size: 0.8rem;
  color: ${theme.colors.white};
`;

export const Col = styled.div``;

export const Row = styled.div``;

export const LogoBox = styled.div`

  @media screen and (max-width: 768px) {
    margin-bottom: 22px;
  }

  .backed-by {
    
    margin-top: 16px;
    > span {
      color: ${theme.colors.white};
      display: flex;
      align-items: center;
      justify-content: left;
      font-weight: ${theme.fontWeight.light};
      font-size: 16px;
      > img {
        margin-left: 5px;
      }
    }
  }
`;

export const SocialList = styled.ul`
  max-width: 510px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    padding: 5px 14px;
    justify-content: space-around;
  }
`;

export const Li = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-around;

  > button {
    margin: 0;

    > span {
      margin-left: 8px;
    }
  }

  @media screen and (max-width: 425px) {
    margin: 20px 20px 0px 0px;
  }
`;
