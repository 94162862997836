import { useState, useEffect } from "react";
import { firestore, analytics } from "../../firebase";

const useForm = (validate) => {
  const [values, setValues] = useState({ linkedin: '' });
  const [errors, setErrors] = useState({});
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const handleDownload = () => {
    analytics.logEvent('ebook_file_downloaded');
    window.open('https://devpass-api-bucket.s3.amazonaws.com/landingpage/Insights_into_the_Rising_Wave_of_Developer_Experience.pdf', '_blank');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validate(values));

    if (Object.keys(values).length >= 2) {
      firestore.collection("ebook-devex").add({
        name: values.name,
        linkedin: values.linkedin,
        email: values.email,
        timestamp: Math.floor(Date.now() / 1000)
      })
      .then(() => {
        setShouldSubmit(true);
      })
      .catch((errors) => {
        setShouldSubmit(false);
      })
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && shouldSubmit) {
      handleDownload();
    }
  }, [errors, shouldSubmit]);

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
    setErrors((errors) => ({ ...errors, [event.target.name]: "" }));
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  };
};

export default useForm;
