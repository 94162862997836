import styled from 'styled-components';

export const Container = styled.header`
  z-index: 2;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 100px;
  background: rgb(249, 250, 252, 0.85);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  top: 0;
  
  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > button {
      margin: 0;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 24px;
  }
`;

export const ImgWrapper = styled.div`
  > img {
    width: 160px;
    height: 38px;
  }
`;
