import styled from 'styled-components';
import theme from '../../theme';

export const TopBanner = styled.div`
  position: relative;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 48px;

  > img {
    margin-top: 12px;
    margin-bottom: 12px;
    margin-right: 24px;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`;

export const Text = styled.p`
  font-size: 16px;
  text-align: center;
  color: white;
  color: #ABCBFF;
  font-weight: ${theme.fontWeight.regular};

  > medium {
    color: #ffffff;
    font-weight: ${theme.fontWeight.medium};

    > img {
        height: 15px;
    }
  }

`;
