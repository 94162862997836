import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAAkhEAh8sS1E9hqshmQ7qDAkh_NCS4cxo",
  authDomain: "devpass-6cd64.firebaseapp.com",
  databaseURL: "https://devpass-6cd64-default-rtdb.firebaseio.com",
  projectId: "devpass-6cd64",
  storageBucket: "devpass-6cd64.appspot.com",
  messagingSenderId: "261520459998",
  appId: "1:261520459998:web:bc662be2157a2e78b99a4f",
  measurementId: "G-PSZBEMTF8C"
};

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const analytics = firebase.analytics();

export { analytics, firestore };
