import { createGlobalStyle } from 'styled-components';

const Styles = createGlobalStyle`

  body,
  html,
  a {
    font-family: 'Ubuntu', sans-serif;
    margin: 0;
    padding: 0;
  }

  body {
    margin:0;
    padding:0;
    border: 0;
    outline: 0;
    background: #fff;
    overflow-x: hidden;
    position: relative;
    box-sizing: border-box;

    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
  }

  #root {
    overflow-x: hidden;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100vw;
    width: 100%;
    min-height: 100vh;
  }

  a:hover {
    color: #000;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #0a1f44;
    font-size: 2.575rem;
    line-height: 3.0625rem;
  
    @media only screen and (max-width: 414px) {
      font-size: 1.625rem;
    }
  }

  p {
    color: #343D48;
    font-size: 1.125rem;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  h1 {
    font-weight: 600;
  }

  a {
    text-decoration: none;
    outline: none;
    color: #2E186A;

    :hover {
      color: #2e186a;
    }
  }
  
  *:focus {
    outline: none;
  }

  .about-block-image svg {
    text-align: center;
  }

  @keyframes flashing {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }  
}
`;

export default Styles;
