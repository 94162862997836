import { useState, useEffect, useRef } from 'react';
import Section from '../../common/Section';
import SvgIcon from '../../common/SvgIcon';
import * as S from './styles';
import { PopupButton } from '@typeform/embed-react';
import { analytics } from '../../firebase';

const HowItWorks = () => {  
  const [isTablet, setTablet] = useState(window.innerWidth <= 768);
  const downloadButton = useRef();

  const updateMedia = () => {
    setTablet(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const downloadReport = () => {
    analytics.logEvent('download_report_button_clicked');
    downloadButton?.current.open();
  };

  return (
    <Section>
      <S.Wrapper>
        <S.Title>
          Crafting tailored Dev Experiences
        </S.Title>
        <S.Subtitle>
          A Game-Changer in learning by doing, Devpass is an innovative AI experience aiming to{isTablet ? ' ' : <br />}enhance your company's Dev Experience
        </S.Subtitle>

        <S.InfoList>
          <S.InfoItem>
            <SvgIcon src="crafting-01.svg"/>
            <S.TextBox>
              <span><strong>1.</strong></span>
              <span>Generate hands-on simulations based on your tech stack and coding patterns and share through your corporate communicator.</span>
            </S.TextBox>
          </S.InfoItem>

          <S.InfoItem>
            <SvgIcon src="crafting-02.svg" />
            <S.TextBox>
              <span><strong>2.</strong></span>
              <span>Our Bot prioritizes tasks, shares tips, and stands ready to assist engineers, ensuring an interactive and guided learning experience.</span>
            </S.TextBox>
          </S.InfoItem>
        </S.InfoList>
        <S.InfoList>
          <S.InfoItem>
            <SvgIcon src="crafting-03.svg" />
            <S.TextBox>
              <span><strong>3.</strong></span>
              <span>At the end of each simulation, Devpass Bot validates the solution, sharing feedback that helps software engineers boost their skills.</span>
            </S.TextBox>
            </S.InfoItem>

          <S.InfoItem>
            <SvgIcon src="crafting-04.svg" />
            <S.TextBox>
              <span><strong>4.</strong></span>
              <span>Unlock weekly insights on team performance, engagement and skill proficiency.<br/></span>
            </S.TextBox>
            <S.Link onClick={downloadReport}>Download a sample version</S.Link>
            <PopupButton id="PRkeiio6" size={80} ref={downloadButton} style={{display: 'none'}}/>
          </S.InfoItem>
        </S.InfoList>
      </S.Wrapper>
    </Section>
  );
};

export default HowItWorks;
