import {FloatingWhatsApp} from 'react-floating-whatsapp';

const FloatingWhatsapp = () => {
  return (
    <FloatingWhatsApp 
      phoneNumber='+5511951781209'
      accountName='Devpass Team'
      avatar='/img/svg/avatar-whatsapp.png'
      statusMessage='Reply within a few minutes'
      chatMessage='Hey! How can we help you? 👋'
      placeholder='Type your message'
      styles={{zIndex: '999'}}
    />
  )
}

export default FloatingWhatsapp;
