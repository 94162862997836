import React from 'react'
import * as S from './styles';
import EbookHeader from '../../components/EbookHeader';
import Ebook from '../../components/Ebook';

const EbookPage = () => {
  return (
    <S.Container>
      <EbookHeader />
      <Ebook />
    </S.Container>
  );
}

export default EbookPage;
