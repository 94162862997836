import styled from 'styled-components';
import theme from '../../theme';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 72px 0px 72px;

  @media screen and (max-width: 1024px) {
    margin: 0 55px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 8px;
    margin: 0 15px 0;
  }
`;

export const Title = styled.h2`
  font-size: 40px;
  color: #000000;
  font-weight: ${theme.fontWeight.medium};
  text-align: center;

  @media screen and (max-width: 425px) {
    font-size: 35px;
  }
`;

export const InfoList = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1100px;
  margin: 0 0 28px 0;
`;

export const InfoItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 290px;
  width: calc(100% / 3);
  margin: 24px 4px;

  > img {
    width: 110px;
    height: 110px;
  }

  > span {
    text-align: center;
    font-weight: ${theme.fontWeight.light};
    font-size: 16px;
    line-height: 22px;

    > strong {
      color: ${theme.colors.defaultBlue};
      font-weight: ${theme.fontWeight.bold};
    }
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 20px);
  }

  @media screen and (max-width: 425px) {
    padding: 20px;
  }
`;

export const InfoTitle = styled.h3`
  font-size: 24px;
  font-weight: ${theme.fontWeight.medium};
  line-height: 32px;
  letter-spacing: 0.01em;
  text-align: center;
`;
