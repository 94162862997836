import styled from 'styled-components';
import theme from '../../theme';

export const Button = styled.button`
  background: ${(props) => props.color || '#2878F9'};
  color: ${(props) => props.labelColor || '#fff'};
  font-size: 1rem;
  font-weight: ${theme.fontWeight.medium};
  font-family: 'Ubuntu';
  min-width: ${(props) => props.width || '159px'};
  height: 46px;
  border: 2px solid;
  border-color: ${(props) => props.borderColor || '#2878F9'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  outline: none;
  cursor: pointer;
  margin-top: 0.525rem;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
