import styled from 'styled-components';
import theme from '../../theme';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 170px 100px;
  background-color: #2878F9;

  @media screen and (max-width: 1024px) {
    padding: 72px 72px 70px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  @media screen and (max-width: 425px) {
    padding: 32px 32px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  width: 400px;
  height: 450px;
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    margin-top: 48px;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
`;

export const Title = styled.h3`
  font-size: 64px;
  color: #000000;
  font-weight: ${theme.fontWeight.medium};
  margin-bottom: 24px;
  margin-top: 0px;
  line-height: 72px;

  @media screen and (max-width: 1024px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 24px;
  }

  @media screen and (max-width: 425px) {
  }
`;


export const Subtitle = styled.p`
font-size: 16px;
 color: #000000;
 font-weight: ${theme.fontWeight.regular};
 line-height: 150%;

 > strong {
  font-weight: ${theme.fontWeight.bold};
 }
`;

export const FormTitle = styled.p`
  font-size: 24px;
  color: #000000;
  font-weight: ${theme.fontWeight.medium};
  margin-bottom: 0px;
`;

export const FormSubtitle = styled.p`
  font-size: 14px;
  color: #000000;
  font-weight: ${theme.fontWeight.regular};
  margin-bottom: 10px;
`;

export const FormGroup = styled.form`
  width: 275px;
`;

export const Span = styled.span`
  display: block;
  font-weight: ${theme.fontWeight.regular};
  font-size: 14px;
  color: red;
  margin-top: 8px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`;