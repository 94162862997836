import { useRef } from 'react';
import * as S from './styles';
import SvgIcon from '../../common/SvgIcon';
import Button from '../../common/Button';
import theme from '../../theme'
import { analytics } from "../../firebase";
import { PopupButton } from '@typeform/embed-react';

const Header = () => {
  const typeformButton = useRef();

  const handleCTAButton = () => {
    analytics.logEvent('call_to_action_button_clicked', { source: 'header' });
    typeformButton.current?.open();
  };

  return (
    <S.Container>
      <S.ImgWrapper>
        <SvgIcon
          src="devpass-logo.svg"
          height="50"
          alt="logo-devpass"
          title="Logo Devpass"
        />
      </S.ImgWrapper>
      <div>
        <Button
          labelColor={theme.colors.white}
          color={theme.colors.defaultBlue}
          borderColor={theme.colors.defaultBlue}
          onClick={() => handleCTAButton()}
        >
          <span>Start Free Trial</span>
        </Button>
        <PopupButton id="FrPTFZyJ" size={80} ref={typeformButton} style={{display: 'none'}}/>
      </div>
    </S.Container>
  );
}

export default Header
