import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  position: relative;
  z-index: 1;
  background: url('/img/svg/hero-bg.svg');
  width: 100%;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 100px 100px 0px;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    padding: 90px 100px 0px;
  }

  @media screen and (max-width: 1024px) {
    padding: 90px 50px 0px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 30px 0 30px;
  }

  @media screen and (max-width: 425px) {
    padding: 20px 0 0 0;
  }
`;

export const H1 = styled.h1`
  font-weight: ${theme.fontWeight.regular};
  font-size: 56px;
  color: #0A1F44;
  text-align: center;
  margin: 0 110px 30px;
  line-height: 65px;

  @media screen and (max-width: 1440px) {
    margin: 0 50px 30px;
    font-size: 48px;
  }

  @media screen and (max-width: 1024px) {
    margin: 0 50px 30px;
    font-size: 36px;
    line-height: 45px;
  }

  @media screen and (max-width: 768px) {
    margin: 30px 20px 20px 20px;
    font-size: 36px;
  }

  > strong {
    font-weight: ${theme.fontWeight.bold};
  }
`;

export const Subtitle = styled.span`
  font-weight: ${theme.fontWeight.light};
  font-size: 28px;
  color: #0A1F44;
  text-align: center;
  line-height: 40px;

  @media screen and (max-width: 1024px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media screen and (max-width: 425px) {
    margin: 0 20px;
  }
`;

export const Text = styled.span`
  font-weight: ${theme.fontWeight.regular};
  font-size: 16px;
  color: #2878F9;
  margin-bottom: 8px;
  display: inline-flex;
  align-items: center;

  > img {
    margin-right: 4px;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lead-button {
    background: '#2878F9';
    color: '#fff';
    font-size: 1rem;
    font-weight: ${theme.fontWeight.medium};
    font-family: 'Ubuntu';
    min-width: 159px;
    height: 46px;
    border: 2px solid;
    border-color: '#2878F9';
    border-radius: '8px';
    outline: none;
    cursor: pointer;
    margin-top: 0.525rem;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ScrollWrapper = styled.div`
  margin-top: 80px;
  margin-bottom: 44px;
  > button {
    margin-bottom: ;
    width: 60px;
    height: 60px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    pointer-events: all;

    > img {
      width: 60px;
      height: 60px;
    }
  }

  @media screen and (max-width: 1024px) {
    margin: 60px 0px 44px 0px;
  }
`;

export const TopLeftImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  > img {

    @media screen and (max-width: 768px) {
      width: 250px;
    }

    @media screen and (max-width: 425px) {
      width: 200px;
    }
  }
`;

export const TopRightImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  > img {

    @media screen and (max-width: 768px) {
      height: 150px;
    }

    @media screen and (max-width: 425px) {
      height: 100px;
    }
  }
`;

export const BottomRightImage = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  > img {

    @media screen and (max-width: 1024px) {
      width: 250px;
    }

    @media screen and (max-width: 768px) {
      width: 200px;
    }

    @media screen and (max-width: 425px) {
      width: 150px;
    }
  }
`;

export const BottomLeftImage = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  > img {

    @media screen and (max-width: 768px) {
      width: 250px;
    }

    @media screen and (max-width: 425px) {
      width: 150px;
    }
  }
`;
