import styled from 'styled-components';
import theme from '../../theme';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 72px 0px 72px;
  margin: 0px 100px;

  > div {
    position: relative;

    .blink-button {
      position: absolute;
      bottom: 40%;
      left: 50%;
      transform: translate(-50%, 50%);
      animation: flashing 2s infinite;

      @media screen and (max-width: 768px) {
        min-width: 90px;
        height: 28px;
      }
    }
  }

  > div > img {
    @media screen and (max-width: 768px) {
      width: 90vw;
    }
  }

  @media screen and (max-width: 1024px) {
    margin: 0 55px 0;
  }

  @media screen and (max-width: 768px) {
    margin: 0 32px 0;
  }
`;

export const Title = styled.h2`
  font-size: 40px;
  color: #000000;
  font-weight: ${theme.fontWeight.medium};
  text-align: center;

  @media screen and (max-width: 425px) {
    font-size: 35px;
  }
`;

export const Subtitle = styled.span`
  font-size: 22px;
  font-weight: ${theme.fontWeight.light};
  line-height: 32px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #0A1F44;
`;

export const InfoList = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
`;

export const InfoItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 28px 14px;

  > img {
    width: 483px;
  }

  > span {
    width: 483px;
    text-align: left;
    font-weight: ${theme.fontWeight.light};
    font-size: 18px;
    line-height: 24px;
    margin-top: 8px;

    > strong {
      color: ${theme.colors.defaultBlue};
      font-weight: ${theme.fontWeight.bold};
      font-size: 24px;
    }
  }

  @media screen and (max-width: 1024px) {
    > img {
      width: 350px;
    }

    > span {
      width: 350px;
    }
  }

  @media screen and (max-width: 768px) {
    > img {
      width: 300px;
    }

    > span {
      width: 300px;
    }
  }

  @media screen and (max-width: 425px) {
    margin: 14px 0px;
  }
`;

export const TextBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 483px;

  > span {
    text-align: left;
    font-weight: ${theme.fontWeight.light};
    font-size: 18px;
    line-height: 24px;
    margin-top: 8px;
    margin-left: 8px;

    > strong {
      color: ${theme.colors.defaultBlue};
      font-weight: ${theme.fontWeight.bold};
      font-size: 24px;
      margin-left: 0px;
    }
  }

  @media screen and (max-width: 1024px) {
      width: 350px;
  }

  @media screen and (max-width: 768px) {
    width: 300px;
  }
`;

export const Link = styled.p`
  color: ${theme.colors.defaultBlue};
  font-weight: ${theme.fontWeight.medium};
  text-decoration: underline;
  cursor: pointer;
  margin-left: 68px;
  margin-top: 8px;
  width: 100%;
`;