import { useState, useEffect, useRef } from 'react';
import Button from '../../common/Button';
import theme from '../../theme';
import { analytics } from '../../firebase';
import * as S from './styles';
import SvgIcon from '../../common/SvgIcon';
import { TypeAnimation } from 'react-type-animation';
import { PopupButton } from '@typeform/embed-react';

const Hero = ({ scrollTo }) => {
  const [isMobile, setMobile] = useState(window.innerWidth <= 425);
  const typeformButton = useRef();

  const updateMedia = () => {
    setMobile(window.innerWidth <= 425);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const handleScroll = (scrollTo) => {
    const element = document.getElementById(scrollTo);

    element.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  const handleCTAButton = () => {
    analytics.logEvent('call_to_action_button_clicked', { source: 'hero' });
    typeformButton.current?.open();
  };

  return (
    
    <S.Container>
      <div style={{position: 'relative'}}>
        <S.TopLeftImage>
          <SvgIcon src="hero-top-left.svg" />
        </S.TopLeftImage>
        <S.TopRightImage>
          <SvgIcon src="hero-top-right.svg" />
        </S.TopRightImage>
        <S.BottomLeftImage>
          <SvgIcon src="hero-bottom-left.svg" />
        </S.BottomLeftImage>
        <S.BottomRightImage>
          <SvgIcon src="hero-bottom-right.svg" />
        </S.BottomRightImage>
        <S.Wrapper>
          <S.H1>
            Revolutionize <strong>Developer {
              <TypeAnimation 
              sequence={['Training', 2000, 'Onboarding', 2000, 'Productivity', 2000, 'Engagement', 2000]}
              style={{ fontSize: '1em' }}
              repeat={Infinity}
              />
              }</strong>{isMobile ? ' ' : <br />}with Devpass AI Experience
          </S.H1>
          <S.Subtitle>
            Craft immersive AI simulations that mirror real-life scenarios and{isMobile ? ' ' : <br />}take "learn by doing" to the next level
          </S.Subtitle>
          <S.ActionWrapper>
            <S.Text><img src="/img/svg/shield.svg" alt="scroll-down"/> No sensitive data needed</S.Text>
            <div>
              <Button
                color={theme.colors.defaultBlue}
                labelColor={theme.colors.white}
                borderColor={'transparent'}
                onClick={() => handleCTAButton()}
              >
                <span>Start Free Trial</span>
              </Button>
              <PopupButton id="FrPTFZyJ" size={80} ref={typeformButton} style={{display: 'none'}}/>
            </div>            
          </S.ActionWrapper>

          <S.ScrollWrapper>
            <button onClick={() => handleScroll(scrollTo)} labelcolor="#fff">
              <img src="/img/svg/scroll-down.svg" alt="scroll-down" />
            </button>
          </S.ScrollWrapper>
        </S.Wrapper>
      </div>
    </S.Container>
  );
};

export default Hero;
