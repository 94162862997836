import * as S from './styles';

const Section = (
  {
    children,
    id,
    backgroundColor,
    padding,
    margin
  }
) => {
  return (
    <S.Section
      id={id}
      style={{ backgroundColor: backgroundColor, padding: padding, margin: margin }}
    >
      {children}
    </S.Section>
  )  
}

export default Section;
