import Section from '../../common/Section';
import SvgIcon from '../../common/SvgIcon';
import * as S from './styles';

const CompaniesUsingDevpass = ({id}) => {
  return (
    <Section padding={'48px 0 48px'} backgroundColor={'#033387'}>
      <S.Wrapper id={id}>
        <S.H2>Used by software development teams at</S.H2>
        <S.CompaniesList>
          <S.Li>
            <SvgIcon width="100%" src="ifood.svg" />
          </S.Li>

          <S.Li>
            <SvgIcon width="100%" src="rover.svg" />
          </S.Li>

          <S.Li>
            <SvgIcon width="100%" src="cora.svg" />
          </S.Li>

          <S.Li>
            <SvgIcon width="100%" src="gupy.svg" />
          </S.Li>
        </S.CompaniesList>
      </S.Wrapper>
    </Section>
  );
}

export default CompaniesUsingDevpass;
