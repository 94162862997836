import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  width: 275px;
  height: 36px;
  margin-top: 14px;
  text-align: left;
`;

export const Input = styled.input`
  width: 255px;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  border-color: '#000000';
  outline: none;
  font-size: 12px;
  padding-left: 16px;
  border-radius: 4px;
`;
