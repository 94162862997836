
import * as S from './styles';
import Button from '../../common/Button';
import { analytics } from "../../firebase";

const EbookHeader = () => {

  const handleBackButton = () => {
    analytics.logEvent('back_to_devpass_button_clicked');
    window.open('/', '_self');
  };

  return (
    <S.Container>
      <Button
          labelColor='#235DBC'
          color='rgb(0, 0, 0, 0.0)'
          borderColor='#235DBC'
          onClick={() => handleBackButton()}
        >
          <span>← Back to <strong>devpass.com</strong></span>
        </Button>
    </S.Container>
  );
}

export default EbookHeader
