import * as S from "./styles";

const TextInput = ({ id, name, placeholder, onChange, width, t }) => (
  <S.Container width={width}>
    <S.Input
      spellCheck="false"
      placeholder={placeholder}
      name={name}
      id={name}
      onChange={onChange}
    />
  </S.Container>
);

export default TextInput;
