import * as S from './styles';

const Button = ({
  color,
  labelColor,
  width,
  children,
  onClick,
  borderRadius,
  borderColor,
  className,
}) => (
  <S.Button
    width={width}
    onClick={onClick}
    borderRadius={borderRadius}
    style={{
      background: color || '#2878F9',
      color: labelColor || '#fff',
      borderColor: borderColor,
    }}
    className={`${className} devpass-button`}
  >
    {children}
  </S.Button>
);

export default Button;
