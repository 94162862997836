import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  position: relative;
  z-index: 1;
  background: url('/img/svg/call-to-action-bg.svg');
  width: 100%;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 304px;
  padding: 0px 72px 0px 72px;
`;

export const Title = styled.h2`
  font-size: 40px;
  color: #000000;
  font-weight: ${theme.fontWeight.medium};
  text-align: center;

  @media screen and (max-width: 425px) {
    font-size: 25px;
    line-height: 40px;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;

  .devpass-button {
    margin: 0 8px;
  }
`;

export const LeftImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  > img {

    @media screen and (max-width: 768px) {
      width: 150px;
    }

    @media screen and (max-width: 425px) {
      width: 100px;
    }
  }
`;

export const RightImage = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  > img {

    @media screen and (max-width: 768px) {
      width: 150px;
    }

    @media screen and (max-width: 425px) {
      width: 100px;
    }
  }
`;

