
import * as S from './styles';
import SvgIcon from '../../common/SvgIcon';
import theme from '../../theme';
import Button from '../../common/Button';
import { analytics } from "../../firebase";

const Footer = () => {
  const SocialLink = ({ href, src, color }) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="30px" height="30px" color={color} />
      </a>
    );
  };

  const handleDiscordButton = () => {
    analytics.logEvent('join_community_button_clicked');
    window.open('https://discord.gg/yXGRtyZNxA', '_blank');
  }

  return (
    <S.Footer>
      <S.LogoBox>
        <S.NavLink to="/">
          <S.LogoContainer>
            <SvgIcon
              src="devpass-logo-white.svg"
              aria-label="homepage"
              height="42px"
            />
          </S.LogoContainer>
        </S.NavLink>
        <div className="backed-by">
          <span>
            Backed by <img src="/img/svg/techstars.svg" alt="scroll-down" />
          </span>
        </div>
        <S.MinPara>
          @ 2024 Devpass - All Rights Reserved
        </S.MinPara>
      </S.LogoBox>
      <S.SocialList>
        <S.Li>
        <SocialLink
          color={theme.colors.defaultBlue}
          href="https://www.linkedin.com/company/devpassbr"
          src="linkedin.svg"
        />
        </S.Li>
        <S.Li>
        <SocialLink
          color={theme.colors.defaultBlue}
          href="https://twitter.com/devpasstech"
          src="x.svg"
        />
        </S.Li>
        <S.Li>
        <SocialLink
          color={theme.colors.defaultBlue}
          href="https://www.instagram.com/devpasstech"
          src="instagram.svg"
        />
        </S.Li>
        <S.Li>
        <SocialLink
          color={theme.colors.defaultBlue}
          href="https://www.youtube.com/devpass"
          src="youtube.svg"
        />
        </S.Li>

        <S.Li>
        <Button
          color={'transparent'}
          borderColor={theme.colors.white}
          labelColor={theme.colors.white}
          onClick={() => handleDiscordButton()}
        >
          <SvgIcon src="discord.svg" />
          <span style={{paddingLeft: "10px"}}>Join our community</span>
        </Button>
        </S.Li>
      </S.SocialList>
    </S.Footer>
  );
};

export default Footer;
