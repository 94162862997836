import Section from '../../common/Section';
import SvgIcon from '../../common/SvgIcon';
import * as S from './styles';

const Benefits = () => {
  return (
    <Section>
      <S.Wrapper>
        <S.Title>
          The Devpass Experience
        </S.Title>
        <S.InfoList>
          <S.InfoItem>
            <SvgIcon src="benefits-lock.svg" color={'red'} />
            <S.InfoTitle>No Sensitive Data<br/>Needed</S.InfoTitle>
            <span>Don't worry. Devpass prioritizes data security, crafting tailored learning experiences <strong>without accessing your sensitive data or production code</strong>.</span>
          </S.InfoItem>

          <S.InfoItem>
            <SvgIcon src="benefits-terminal.svg" />
            <S.InfoTitle>Engaging Learning<br/>Experience</S.InfoTitle>
            <span>Immerse developers in real-world challenges with Devpass, <strong>ensuring over 90% completion rates</strong>. Transform learning into an exciting journey they won't forget.</span>
          </S.InfoItem>

          <S.InfoItem>
            <SvgIcon src="benefits-star.svg" />
            <S.InfoTitle>Insightful Team<br/>Reports</S.InfoTitle>
            <span>Get in-depth <strong>reports on team performance</strong> with Devpass, empowering leaders for continuous improvement. Uncover valuable insights and experience transformative benefits with us.</span>
          </S.InfoItem>

          <S.InfoItem>
            <SvgIcon src="benefits-groups.svg" />
            <S.InfoTitle>Customized Skill<br/>Development</S.InfoTitle>
            <span>Accelerate learning with <strong>tailored simulations for onboarding and upskilling</strong>. Engineers engage with coding from day one, fostering continuous skill development.</span>
          </S.InfoItem>

          <S.InfoItem>
            <SvgIcon src="benefits-money.svg" />
            <S.InfoTitle>Cut Training Costs<br/>in Half</S.InfoTitle>
            <span>Empower your team with AI-driven interactive simulations, <strong>cutting expenses by 50%</strong>. While leaders monitor and strategically intervene, they can focus on higher-level strategy.</span>
          </S.InfoItem>

          <S.InfoItem>
            <SvgIcon src="benefits-rocket.svg" />
            <S.InfoTitle>Partnering for<br/>Success</S.InfoTitle>
            <span>Devpass is your partner for success. Collaborate confidently with our support for simulation creation. Together, <strong>we secure your training needs and success</strong>.</span>
          </S.InfoItem>
        </S.InfoList>
      </S.Wrapper>
    </Section>
  );
}

export default Benefits;
