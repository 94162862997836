import React from 'react'
import * as S from './styles';
import Hero from '../../components/Hero'
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import CompaniesUsingDevpass from '../../components/CompaniesUsingDevpass';
import HowItWorks from '../../components/HowItWorks';
import Benefits from '../../components/Benefits';
import CallToAction from '../../components/CallToAction';
import TopBanner from '../../components/TopBanner';
import { useState } from 'react';

const Home = () => {
  const [hidden, setHidden] = useState(false);

  return (
    <S.Container>
      { !hidden ? <TopBanner hideBanner={() => { setHidden(true); }} /> : <></> }
      
      <Header />
      <Hero scrollTo="companies" />
      <CompaniesUsingDevpass id="companies" />
      <HowItWorks />
      <Benefits />
      <CallToAction />
      <Footer />
    </S.Container>
  );
}

export default Home;
