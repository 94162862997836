import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import EbookPage from './pages/EbookPage';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/insights-into-the-rising-wave-of-developer-experience" element={<EbookPage />} />
      </Routes>
  </BrowserRouter>
  )
}

export default Router;
