import { analytics } from '../../firebase';

import * as S from './styles';

const TopBanner = ({hideBanner}) => {

  const openLink = () => {
    window.open('/insights-into-the-rising-wave-of-developer-experience', '_blank');
    analytics.logEvent('top_banner_clicked', { message: '’Insights into the Rising Wave of Developer Experience’ is now available.', link: 'https://devpass.com/insights-into-the-rising-wave-of-developer-experience' });
  };

  return (<S.TopBanner >
      <S.Container onClick={openLink}>
        <S.Text>'Insights into the Rising Wave of Developer Experience' is now available. <medium>Get the report for free <img src="/img/svg/download.svg" alt="download"/></medium></S.Text>
      </S.Container>
      <img onClick={hideBanner} src="/img/svg/close-banner.svg" alt="close banner"/>
    </S.TopBanner>);
};

export default TopBanner;
