import { useRef } from 'react';
import Button from '../../common/Button';
import * as S from './styles';
import theme from '../../theme';
import { analytics } from '../../firebase';
import SvgIcon from '../../common/SvgIcon';
import { PopupButton } from '@typeform/embed-react';

const CallToAction = ({id}) => {
  const typeformButton = useRef();
  
  const handleCTAButton = () => {
    analytics.logEvent('call_to_action_button_clicked', { source: 'calltoaction' });
    typeformButton.current?.open();
  };

  const handleBookAMeetingButton = () => {
    analytics.logEvent('contact_us_button_clicked', { source: 'calltoaction' });
    window.open('https://calendly.com/devpass/30min-meeting', '_blank');
  };

  return (
    <S.Container id={id}>
      <div style={{position: 'relative'}}>
        <S.LeftImage>
          <SvgIcon src="call-to-action-left.svg" />
        </S.LeftImage>

        <S.RightImage>
          <SvgIcon src="call-to-action-right.svg" />
        </S.RightImage>

        <S.Wrapper>
          <S.Title>
            Create your first simulation with Devpass
          </S.Title>

          <S.ActionWrapper>
              <Button
                color={theme.colors.defaultBlue}
                labelColor={theme.colors.white}
                borderColor={'transparent'}
                onClick={() => handleCTAButton()}
              >
                <span>Start Free Trial</span>
              </Button>
              <Button
                color={'transparent'}
                labelColor={'black'}
                borderColor={'black'}
                onClick={() => handleBookAMeetingButton()}
              >
                <span>Book a Meeting</span>
              </Button>
              <PopupButton id="FrPTFZyJ" size={80} ref={typeformButton} style={{display: 'none'}}/>
          </S.ActionWrapper>
        </S.Wrapper>
      </div>
    </S.Container>
  );
}

export default CallToAction;
