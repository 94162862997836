export const theme = {
  colors: {
    gray: "#5B5F66",
    darkGray: "#343D48",
    defaultBlue: "#2878F9",
    darkBlue: "#0A1F44",
    white: "#fff",
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    black: 900,
  }
}


export default theme;
