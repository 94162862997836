import Section from '../../common/Section';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';

import theme from '../../theme';
import useForm from "./useForm";
import validate from "./validationRules";
import * as S from './styles';

const Ebook = () => {
    const { values, errors, handleChange, handleSubmit } = useForm(validate);

    const ValidationType = ({ type }) => {
        const ErrorMessage = errors[type];
        return errors[type] ? (
            <S.Span>{ErrorMessage}</S.Span>
        ) : (
          <></>
        );
      };

  return (
    <Section>
      <S.Wrapper>
        <S.TextWrapper>
          <S.Title>Insights into the Rising Wave of Developer Experience</S.Title>
          <S.Subtitle>
            Over the past 2 years, the Devpass team has interacted with a variety of profiles in the technology market in approximately 200 meetings. The information in this report is derived from the knowledge acquired during this period.
          </S.Subtitle>
          <S.Subtitle>
            We hope that by the end of this reading, you will have gained a deeper understanding of...
          </S.Subtitle>
          <S.Subtitle>
            • Why is Developer Experience the new trend in the tech market?<br/>• Developer Experience as a productivity boost strategy          
          </S.Subtitle>
        </S.TextWrapper>

        <S.FormWrapper>
            <S.Form>
                <S.FormTitle>Get the full report</S.FormTitle>
                <S.FormSubtitle>Complete this form to download the report</S.FormSubtitle>
                <S.FormGroup autoComplete="off" onSubmit={handleSubmit}>
                    <TextInput
                    type='text'
                    name='name'
                    id='Email'
                    placeholder='Full name*'
                    value={values.name || ''}
                    onChange={handleChange}
                    />   
                    <ValidationType type="name" />
                    <TextInput
                    type='text'
                    name='linkedin'
                    id='LinkedIn'
                    placeholder='LinkedIn profile'
                    value={values.linkedin || ''}
                    onChange={handleChange}
                    />                
                    <TextInput
                    type='text'
                    name='email'
                    id='Email'
                    placeholder='Work email address*'
                    value={values.email || ''}
                    onChange={handleChange}
                    />         
                    <ValidationType type="email" />
                    <S.ButtonWrapper>
                        <Button
                        name='submit'
                        type='submit'
                        width='275px'
                        labelColor={theme.colors.white}
                        color='#000000'
                        borderColor='#000000'>
                        <span>Download report</span>
                        </Button>
                    </S.ButtonWrapper>
                </S.FormGroup>
            </S.Form>
        </S.FormWrapper>

      </S.Wrapper>
    </Section>
  );
}

export default Ebook;
