import styled from 'styled-components';
import theme from '../../theme';

export const Wrapper = styled.div`
  width: 100%;
  scroll-margin-top: 141px;
`;

export const H2 = styled.h2`
  color: #F8FAFF;
  font-weight: ${theme.fontWeight.medium};
  font-size: 20px;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 425px) {
    margin: 0 20px 30px 20px;
    line-height: 30px;
  }
`;

export const CompaniesList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 24px;

  @media screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
    justify-content: space-around;
  }
`;

export const Li = styled.li`
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    margin: 5px;
  }

  @media screen and (max-width: 425px) {
    width: 100px;
  }
`;

